// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
//@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocBurst-Intro {
    padding: 50px 2%;

    .blocBurst {
        display: flex;
        justify-content: space-around;
        .bloc, a {
            border: 3px solid #000;
            padding: 25px;
            border-radius: 15px;
            width: 25%;
            transition: .5s;
            height: calc(65vw * 250 / 1920);
            align-items: center;
            justify-content: center;
        }
        a.lienPDF {
            background: #fec847;
            &:hover {
                p {
                    color: #fec847;
                }
            }
        }
        a {
            background: #fec847;
            display: flex;
            .blocLien {
                align-items: center;
                justify-content: center;
                img.img-responsive.hover {
                    display: none;
                }
            }
        } 
        a:hover {
            background: #000;
            p {
                color: #fec847;
            }
            img.img-responsive.hover {
                display: block;
            }
            img.img-responsive.defaut {
                display: none;
            }
        } 
        p {
            font-weight: bold;
            font-size: $font-size-24;
            text-align: center;
            line-height: 1.5;
        }  
    }
    .blocIntro {
        text-align: center;
        padding-top: 100px;
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #000;
        }
    }
}

#blocParallax {
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    padding: 130px 5%;
    text-align: center;

    h4 {
        font-size: $font-size-30;
        font-weight: 500;
        padding-bottom: 10px;
        color: #fff;
    }
    h3 {
        font-weight: bold;
        font-size: $font-size-60;
        color: #fff;
        padding-bottom: 70px;
    }
    a.boutonPlus {
        background: #dbdbdb;
        padding: 10px 75px;
        border-radius: 20px;
        color: #000;
        font-size: $font-size-24;
        transition: 0.3s;
        &:hover {
            background: #fec847;
            transition: 0.3s;
        }
    }
}

#bloc50-50 {
    display: flex;
    justify-content: space-evenly;
    padding: 90px 5%;
    .texte {
        padding-top: 40px;
    }
    .texte-gras {
        padding-top: 60px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-24;
    }
    h4 {
        font-weight: bold;
        font-size: $font-size-32;
        text-transform: uppercase;
        line-height: 1.5;
    }
}

/*******************************************************************************
* SABLIÈRES
*******************************************************************************/

#blocBackground {
    background: url("../images/sablieres_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 100px 2%;

    p {
        font-size: $font-size-24;
        font-weight: normal;
        color: #fff;
        text-align: center;
    }
}

#bloc50-50_sablieres {
    display: flex;
    .left-side, right-side {
        width: 50%;
    }
    .right-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10%;
        h3 {
            font-size: $font-size-30;
            font-weight: bold;
            color: #000;
            padding-bottom: 20px;
        }
        ul {
            padding-top: 35px;
            li {
                font-size: $font-size-24;
                font-weight: normal;
                color: #000;
                line-height: 2;
                text-transform: initial;
            }
        }
    }
}


/*******************************************************************************
* DÉPÔT DE TERRE
*******************************************************************************/

#bloc50-50_depots {
    display: flex;
    .left-side, right-side {
        width: 50%;
    }
    .right-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10%;
        h3 {
            font-size: $font-size-30;
            font-weight: bold;
            color: #000;
            padding-bottom: 20px;
        }
        p {
            font-size: $font-size-24;
            font-weight: normal;
            color: #000;
            padding-top: 35px;
        }
    }
}

#blocBackground_depot {
    background: #000;
    padding: 50px 2%;
    h4 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #fff;
        padding-bottom: 50px;
        text-align: center;
    }
    .texte-icon {
        display: flex;
        justify-content: space-around;
        margin-bottom: -150px;
        .texte {
            position: relative;
            left: 8%;
        }
        .icon {
            z-index: 1;
            position: relative;
            left: 3%;
        }
         h3 {
            font-size: $font-size-60;
            font-weight: bold;
            color: #fff;
        }
        h3.first {
            padding-top: 45px;
        }
        .texte-logo {
            display: flex;
            .texte-autoroute {
                position: relative;
                right: 40px;
            }
        }
    }
}

#bloc50-50_depot {
    display: flex;
    .left-side, .right-side {
        width: 50%;
    }
    .left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img-logo {
            display: flex;
            padding-top: 100px;
           .logo.first {
                padding-right: 80px;
            } 
        }
        p {
            font-size: $font-size-24;
            font-weight: normal;
            color: #000;
            padding-top: 35px;
        }
    }
}

/*******************************************************************************
* FORMULAIRE
*******************************************************************************/

#blocIntro_form {
    padding: 50px 2%;
    text-align: center;
    background: #ffc746;
    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #000;
    }
}

#blocFormulaire {
    padding: 50px 2%;
    .clear-both {
        clear: both;
    }
    label {
        color: #000;
        font-weight: bold;
        font-size: $font-size-26;
        text-transform: uppercase;
    }
    input {
        font-size: $font-size-20;
    }
    input#nom, input#entreprise, input#courriel, input#ville, input#telephone, select#besoins {
        height: 45px;
    }
    .btn {
        background-color: #ffc746;
        color: #fff;
        border: none;
        font-size: $font-size-20;
        font-weight: bold;
        width: 75%;
    }
    #right {
        position: relative;
        left: 75%;
        bottom: 115px;
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#bloc50-50_coordonnees {
    display: flex;
    .left-side, .right-side {
        width: 50%;
    }
    .left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .bloc {
            display: flex;
            align-items: baseline;
            padding-bottom: 30px;
            p {
                padding-left: 20px;
                line-height: 1.2;
                font-size: $font-size-30;
                font-weight: normal;
                color: #000;
            }
        }
        .blocHeures {
            padding-top: 60px;
            .jours-heures {
                display: flex;
                .jours {
                    padding-right: 75px;
                }
            }
            h3 {
                font-size: $font-size-30;
                font-weight: bold;
                color: #000;
                padding-bottom: 10px;
            }
            p {
                font-size: $font-size-30;
                font-weight: normal;
                color: #000;
            }
        }
    }
}
#content {
    h3#firstHeading {
        font-size: $font-size-24;
        font-weight: bold;
        color: #000;
    }  
    a {
        font-size: $font-size-24;
        font-weight: normal;
        color: #000;
        &:hover {
            color: #ffb500;
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1830px) {

#blocBackground_depot .texte-icon {
    margin-bottom: -115px;
}
}

@media screen and (max-width: 1685px) {

#blocBackground_depot .texte-icon {
    margin-bottom: -100px;
}
}

@media screen and (max-width: 1730px) {

#blocBurst-Intro .blocBurst p {
    margin: 0;
}
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    height: calc(70vw * 250 / 1920);
}
}

@media screen and (max-width: 1620px) {
    
#bloc50-50 .left-side {
    width: 45%;
}
#bloc50-50 .right-side {
    width: 40%;
}
#bloc50-50 p br, #bloc50-50 h4 br {
    display: none;
}
#bloc50-50 .texte {
    padding-top: 20px;
}
#bloc50-50 .texte-gras {
    padding-top: 35px;
}
}

@media screen and (max-width: 1580px) {

#blocBackground_depot .texte-icon .icon .img-responsive {
    display: none;
}
#blocBackground_depot .texte-icon {
    margin-bottom: 0;
}
#bloc50-50_depots {
    margin-bottom: 0;
}
}

@media screen and (max-width: 1550px) {

#blocBurst-Intro .blocBurst p {
    font-size: 1rem;
}
}

@media screen and (max-width: 1505px) {

#bloc50-50_depots .right-side {
    padding-left: 7%;
}
}

@media screen and (max-width: 1450px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    padding-left: 1rem;
    padding-right: 1rem;
}
}

@media screen and (max-width: 1390px) {

#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    height: calc(80vw * 250 / 1920);
}
#blocBurst-Intro .blocBurst p {
    font-size: 0.9rem;
}
}

@media screen and (max-width: $size-md-max) {
    
nav.module-menu .menu-navbar {
    height: 80px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu-top, nav.module-menu .menu-navbar .wrapper.right .main-menu {
    height: 45%;
}
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    height: calc(75vw * 250 / 1920);
}
nav.module-menu .menu-navbar.nav-invisible {
    top: -85px;
}
#bloc50-50 .left-side {
    width: 50%;
}
.module-header {
    padding-top: 35px;
}
#bloc50-50_depots .right-side p {
    font-size: 1.1rem;
}
#bloc50-50_depots .right-side h3 {
    font-size: 1.3rem;
}
#bloc50-50_depots {
    margin-bottom: 0;
}
#bloc50-50_depot .left-side {
    padding: 0 2%;
}
#bloc50-50_depot .left-side .img-logo {
    padding-top: 35px;
}
#bloc50-50_sablieres .right-side {
    padding-left: 3%;
}
#bloc50-50_sablieres .right-side h3 {
    padding-bottom: 10px;
}
#bloc50-50_sablieres .right-side ul {
    padding-top: 10px;
}
}

@media screen and (max-width: 1180px) {
    
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    height: calc(80vw * 250 / 1920);
}
#bloc50-50_sablieres .right-side ul {
    padding-top: 0;
}
#bloc50-50_sablieres .right-side {
    padding-top: 15px;
}
#bloc50-50_sablieres .right-side h3 {
    font-size: 1.3rem;
}
}

@media screen and (max-width: 1155px) {

#bloc50-50_depots {
    flex-direction: column;
}
#bloc50-50_depots .left-side, #bloc50-50_depots .right-side {
    width: 55%;
    margin: auto;
    padding: 20px;
}
}

@media screen and (max-width: 1024px) {
   
#blocBurst-Intro .blocBurst p {
    font-size: 1.1rem;
}
#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .left-side, #bloc50-50 .right-side {
    width: 100%;
}
#bloc50-50 .left-side {
    padding-bottom: 25px;
}
#bloc50-50 .left-side .img-responsive {
    margin: auto;
}
.bursts .bursts-container a {
    width: 30%;
    padding-right: 25px;
}
.bursts .bursts-container {
    justify-content: initial;
}
.slideshow-wrap .caption {
    width: 100%;
}
.bursts {
    padding: 80px 25px;
}
#blocBurst-Intro .blocBurst .bloc.last img.img-responsive.pr4 {
    min-width: 120px;
}
#blocBurst-Intro .blocBurst .bloc.last img.img-responsive.pr4 {
    min-width: 0;
    padding-bottom: 15px;
}
#blocBurst-Intro .blocBurst .bloc.flex.last {
    flex-direction: column;
}
#bloc50-50_depot {
    flex-direction: column;
    padding: 50px 0;
}
#bloc50-50_depot .left-side, #bloc50-50_depot .right-side {
    width: 55%;
    margin: auto;
    padding: 20px;
}
#blocBurst-Intro .blocBurst {
    flex-direction: column;
}
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
}
#blocBackground_depot .texte-icon {
    flex-direction: column;
}
#blocBackground_depot .texte-icon .texte, #blocBackground_depot .texte-icon .texte-logo .texte-autoroute {
    position: initial;
    text-align: center;
}
#blocBackground_depot .texte-icon .texte-logo .texte-autoroute {
    padding-right: 35px;
}
#blocBackground_depot .texte-icon .texte-logo {
    justify-content: center;
}
}

@media screen and (max-width: $size-sm-max) {
   
.bursts {
    padding: 80px 15px;
}
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    height: calc(100vw * 250 / 1920);
}
#blocBurst-Intro .blocBurst .bloc.flex.last {
    flex-direction: initial;
}
#blocBurst-Intro .blocBurst .bloc.last img.img-responsive.pr4 {
    min-width: 0;
    padding-bottom: 0;
}
#bloc50-50_coordonnees {
    flex-direction: column;
}
#bloc50-50_coordonnees .left-side, #bloc50-50_coordonnees .right-side {
    width: 100%;
}
#bloc50-50_coordonnees .left-side {
    padding: 50px 2%;
}
#bloc50-50_sablieres {
    flex-direction: column;
}
#bloc50-50_sablieres .left-side, #bloc50-50_sablieres .right-side {
    width: 100%;
}
#bloc50-50_sablieres .right-side {
    padding: 60px;
}
#bloc50-50_sablieres .right-side h3 {
    font-size: 1.5rem;
}
#blocBurst-Intro .blocBurst {
    flex-direction: column;
}
#blocBurst-Intro .blocIntro {
    padding-top: 30px;
}
.bursts .bursts-container a {
    width: 100%;
    padding-bottom: 20px;
    padding-right: 0;
}
.bursts .bursts-container a .img-responsive {
    margin: auto;
}
.bursts {
    padding: 80px 0;
}
#blocBackground_depot .texte-icon {
    flex-direction: column;
    text-align: center;
}
#bloc50-50_depot .left-side .img-logo .logo.first {
    padding-right: 50px;
}
#bloc50-50_depots .left-side, #bloc50-50_depots .right-side, #bloc50-50_depot .left-side, #bloc50-50_depot .right-side {
    width: 100%;
    padding: 0;
}
#bloc50-50_depots .right-side {
    padding: 50px;
}
#bloc50-50_depots .right-side h3 {
    font-size: 1.5rem;
}
#bloc50-50_depots .right-side p {
    font-size: 1.2rem;
}
#bloc50-50_depot {
    padding: 50px 0 0 0;
}
#bloc50-50_depot .left-side {
    padding-bottom: 50px;
}
.module-formulaire.container.my8 {
    width: 100%;
}
#blocFormulaire #right {
    position: relative;
    left: 64%;
    bottom: 115px;
}
}

@media screen and (max-width: $size-xs-max) {
   
.slideshow-wrap .caption {
    top: 38%;
}
#bloc50-50_coordonnees .left-side .blocHeures .jours-heures .jours {
    padding-right: 50px;
}
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    width: 80%;
    height: calc(220vw * 250 / 1920);
}
#bloc50-50_sablieres .right-side {
    padding: 60px 3%;
}
#bloc50-50_depot .left-side, #bloc50-50_depots .right-side {
    width: 85%;
}
#bloc50-50_depot .left-side p br, #bloc50-50_depots .right-side p br {
    display: none;
}
#blocFormulaire #right {
    position: initial;
}
a.btn.btn-send {
    display: none;
}
#blocBackground_depot .texte-icon .texte-logo {
    flex-direction: column;
}
#blocBackground_depot .texte-logo .logo {
    margin: auto;
    padding-top: 10px;
}
}

@media screen and (max-width: 360px) {
   
.slideshow-wrap .caption h4 {
    font-size: 1.3rem;
    margin-bottom: 0px;
}
.slideshow-wrap .caption h3 {
    font-size: 2.7rem;
}
.slideshow-wrap .caption {
    top: 40%;
}
#blocBurst-Intro .blocBurst .bloc, #blocBurst-Intro .blocBurst a {
    height: calc(250vw * 250 / 1920);
}
#bloc50-50_coordonnees .left-side .bloc {
    align-items: center;
}
#bloc50-50_coordonnees .left-side .bloc p, #bloc50-50_coordonnees .left-side .blocHeures p {
    font-size: 1.3rem;
}
#bloc50-50_sablieres .right-side h3 {
    font-size: 1.4rem;
}
#bloc50-50_depot .left-side p br, #bloc50-50_depots .right-side p br {
    display: none;
}
#bloc50-50_depots .right-side {
    padding: 25px;
}
}